export const subImageDashboard = {
  prompt: "Prompt",
  createdOn: "Created on",
  success: "Success, here is the provided link",
  averageRating: "Average rating: ",
  share: "share",
  like: "like",
  callToAction: "Download Our App",
  closeBanner: "Close",
  bannerTitle: "Choose",
  views: "Views"
  
};
