import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

import "react-datepicker/dist/react-datepicker.css";
import "./SubManageCreditCard.scss";
import { INTERNAL_ROUTES } from "../../../constants/internalRoutes.js";
import { useNavigate } from "react-router-dom";
import SubCreditCardDisplay from "../../Common/CreditCardDisplay/SubCreditCardDisplay.jsx"


const SubManageCreditCard = ({ cardObj, deleteCreditCard }) => {
	const navigate = useNavigate();

	const goToEditProfile = (profileNum) => {
		navigate(`${INTERNAL_ROUTES.PAYMENT.EDIT_PAYMENT_PROFILE}?id=${profileNum}`);
	};


	return (
		<tr data-testid="container-manage-credit-card">
			<td>
				<SubCreditCardDisplay cardObj={cardObj} />
			</td>
			<td>
				<div>
					{cardObj?.expiry?.slice(0, 2)} / {cardObj?.expiry?.slice(2)}
				</div>
			</td>
			<td
				className="manage-credit-card-edit-icon"
				onClick={(e) => {
					goToEditProfile(cardObj.profileNum);
				}}
			>
				<p>
					<MdEdit />
				</p>
			</td>
			<td
				className="manage-credit-card-delete-icon"
				onClick={(e) => {
					deleteCreditCard(cardObj);
				}}
			>
				<p>
					<MdDelete />
				</p>
			</td>
		</tr>
	);
};

export default SubManageCreditCard;
