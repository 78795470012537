import React, { useState } from "react";
import "./SmartHelp.scss";
import { translations } from "../../../../translations/texts";
import { useSelector } from "react-redux";
import NomadsFetchRequests from "../../../../requests";
import SubButton from "../../../Common/Button/SubButton";
import SubLoadingButton from "../../../Common/Loading/LoadingButton/SubLoadingButton";

const SmartHelp = () => {
	const [loading, setLoading] = useState(false);
	const [subject, setSubject] = useState("");
	const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState(false)


	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const user = useSelector((state) => state.user.userData);


	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);

    const data = {
      subject: subject,
      email: user.email,
      description: description
    }

		try {
			const response = await NomadsFetchRequests.support.submitTicket(data);

      if (response.data.code === 200) {
        setSubject("");
			  setDescription("");
      }
      setLoading(false)
      setSuccessMessage(true)

		} catch (error) {
			console.error("Error submitting the form:", error);
      setLoading(false)
		}
	};

	return (
		<div data-testid="container-help" className="nomad-section-container" id="helpCenter">
			<section>
				<h1 className="nomad-section-title">{translations[selectedLanguage].helpCenter.title}</h1>
				{/* <p>{translations[selectedLanguage].common.comingSoon}</p> */}
				<form className="help-form" onSubmit={handleSubmit}>
					<label htmlFor="subject" className="form-label">
						{translations[selectedLanguage].helpCenter.subjectLabel}
					</label>
					<input
						type="text"
						id="subject"
						name="subject"
						className="form-input"
						placeholder={translations[selectedLanguage].helpCenter.subjectPlaceholder}
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					/>

					<label htmlFor="description" className="form-label">
						{translations[selectedLanguage].helpCenter.descriptionLabel}
					</label>
					<textarea
						id="description"
						name="description"
						className="form-input"
						placeholder={translations[selectedLanguage].helpCenter.descriptionPlaceholder}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
          {successMessage && <p className="success-message">{translations[selectedLanguage].helpCenter.success}</p>}
					<SubLoadingButton isLoading={loading} type="submit" label={translations[selectedLanguage].helpCenter.submitButton} />
				</form>
			</section>
		</div>
	);
};

export default SmartHelp;
